/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { Base } from '../../Baseurl/Baseurl'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'; // Swiper components
import './Gallery.css'
import { Modal } from 'antd';

const GalleryImages = ({ map }) => {
  const { pathname } = useLocation()
  const [url, setUrl] = useState([])


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
console.log(currentImageIndex,"currentImageIndex")
  const swiperRef = useRef(null); // Create a ref to Swiper

  const GalleryImages = () => {
    axios.get(`${Base}/api/user/view/gallery`).then((e) => {
   const data = e?.data?.data;
        setUrl(data);
        // Save the data in localStorage for future use
        localStorage.setItem('galleryImages', JSON.stringify(data));
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    // Check if data exists in localStorage
    const storedData = localStorage.getItem('galleryImages');
    if (storedData) {
      setUrl(JSON.parse(storedData)); // Use data from localStorage if available
    } 
      GalleryImages(); // Fetch from API if no data in localStorage
    
  }, []);

  const openModal = (images, index) => {
    setSelectedImages(images); // Set all images
    setCurrentImageIndex(index); // Set the index of the clicked image
    setIsModalVisible(true);
  
    // Ensure Swiper starts at the correct slide
    setTimeout(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideTo(index, 0); // Slide to the correct index instantly
      }
    }, 0);
  };
  
  


  const closeModal = () => {
    setIsModalVisible(false);
  }


  const prevSlide = () => {
    swiperRef.current.swiper.slidePrev(); 
  };
  
  const nextSlide = () => {
    swiperRef.current.swiper.slideNext(); 
  };
  

  return (
    <div className="gallery-area inner-padding" style={{ marginTop: '20px' }}>
      <div className="container">
        <div className="row">
          <div className="col-12 foo">
            <div className="section-title-area">
              <h2 className="section-title" style={{ color: 'black' }}>Bar & Grill Gallery</h2>
              <div className="section-divider" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {/* Gallery Images Grid */}
        <div className="gallery-grid">
          {url.map((e, index) => {
            const totalItems = url.length;
            const isLast = index === totalItems - 1;
            const isSecondLast = index === totalItems - 2;

            return (
              <div
                key={index}
                className="ddddaaa"

              >
                <img
                  src={e.images}
                  alt="responsive img"
n                  style={{
                    height: isLast ? '340px' : isSecondLast ? '362px' : 'auto',
                  }}
                />
                <div className="gallery-overlay">
                  <button className="view-button" onClick={() => openModal(url.length > 0 && url, index)}>
                    View
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>


      <Modal
  visible={isModalVisible}
  onCancel={closeModal}
  footer={null}
  width={800}
  centered
>
  <div className="swiper-container">
    <Swiper
      ref={swiperRef}
      initialSlide={currentImageIndex} 
      spaceBetween={10}
      slidesPerView={1}
      loop={true}
      onSlideChange={(swiper) => setCurrentImageIndex(swiper.realIndex)} 
    >
      {selectedImages.map((image, index) => (
        <SwiperSlide key={index}>
          <img
            src={image.images}
            alt={`image-${index}`}
            style={{ width: '400px', height: '500px', margin: 'auto',objectFit:"cover" }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
    <div className="swiper-button-prev" onClick={prevSlide}></div>
    <div className="swiper-button-next" onClick={nextSlide}></div>
  </div>
</Modal>


    </div>
  )
}

export default GalleryImages;
