import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.css';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const { pathname } = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false); // State to track Navbar.Toggle

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust the scroll value as needed
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 1000,
                color: isScrolled ? 'white !important' : 'black !important',
                backgroundColor: isScrolled ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
                transition: 'background-color 0.3s ease-in-out',
            }}
        >
            <Navbar
                expand="lg"
                expanded={isNavbarExpanded} // Control expanded state
                onToggle={() => setIsNavbarExpanded(!isNavbarExpanded)} // Update state on toggle
                style={{
                    backgroundColor: isNavbarExpanded?'black':'transparent',
                    maxWidth: '100%',
                }}
                className="container m-auto"
            >
                <Container fluid>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="m-auto my-2 my-lg-0 NavBarItems pt-5 pt-md-0 pt-lg-0"
                            style={{
                                maxHeight: '100vh',
                                color: isScrolled ? 'white' : 'white',
                            }}
                            navbarScroll
                        >
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'white',
                                }}
                                className={pathname === '/' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/"
                            >
                                Home
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'white',
                                }}
                                className={pathname === '/events' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/events"
                            >
                                Upcoming Events
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'white',
                                }}
                                className={pathname === '/gallery' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/gallery"
                            >
                                Gallery
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'white',
                                }}
                                className={pathname === '/breakfast_menu' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/breakfast_menu"
                            >
                                Breakfast Menu
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'white',
                                }}
                                className={pathname === '/lunch_dinner' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/lunch_dinner"
                            >
                                Lunch Dinner Menu
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'white',
                                }}
                                className={pathname === '/daily_specials' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/daily_specials"
                            >
                                Daily Specials
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'white',
                                }}
                                className={pathname === '/contact_us' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/contact_us"
                            >
                                Hours/Contact/Map
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default Header;
