// /* eslint-disable no-unused-vars */
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';

// import './styles.css';

// // import required modules
// import { EffectCoverflow, Pagination } from 'swiper/modules';
// import axios from 'axios';
// import { Base } from '../../../Baseurl/Baseurl';
// import { useEffect, useState } from 'react';

// const UpcommingSlider = () => {
//   const [imgSlider, setImgSlider] = useState([]);
//   const [imgSlider2, setImgSlider2] = useState([
//     {
//       image:"./assets/img/my/eventsSlider.png"
//     },{
//       image:"./assets/img/my/eventsSlider.png"
//     },{
//       image:"./assets/img/my/eventsSlider.png"
//     },{
//       image:"./assets/img/my/eventsSlider.png"
//     },{
//       image:"./assets/img/my/eventsSlider.png"
//     },
//   ]);
//   const sliderImg = () => {
//     axios.get(`${Base}/api/user/view_upcoming`).then((e) => {
//       setImgSlider(e?.data.data)
//     }).catch((err) => {
//       console.log(err.message)
//     })
//   }
//   useEffect(() => {
//     sliderImg()
//   }, [])
//   return (
//     <>
//       <Swiper
//         effect={'coverflow'}
//         grabCursor={true}
//         centeredSlides={true}
//         slidesPerView={'auto'}
//         coverflowEffect={{
//           rotate: 50,
//           stretch: 0,
//           depth: 100,
//           modifier: 1,
//           slideShadows: true,
//         }}
//         pagination={true}
//         modules={[EffectCoverflow, Pagination]}
//         className="UpcommingSlider"
//       >
//         {
//           imgSlider.length > 0 ?

//         imgSlider?.map((img, index) => {
//           return (<SwiperSlide key={index} className='swiper-slide333'>
//             <img alt='/' src={img.image} />
//           </SwiperSlide>)
//         }) :   imgSlider2?.map((img, index) => {
//           return (<SwiperSlide key={index} className='swiper-slide333'>
//             <img alt='/' src={img.image} />
//           </SwiperSlide>)
//         })
//       }

//       </Swiper>
//     </>
//   )
// }

// export default UpcommingSlider

/* eslint-disable no-unused-vars */
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import axios from "axios";
import { Base } from "../../../Baseurl/Baseurl";
import { useEffect, useState } from "react";
import Cookies from "js-cookie"; // Import js-cookie

const UpcommingSlider = () => {
  const [imgSlider, setImgSlider] = useState([]);

  const sliderImg = () => {
    // Check if data is already in cookies
    const storedData = Cookies.get("upcomingImages");
    if (storedData) {
      setImgSlider(JSON.parse(storedData)); // Use data from cookies
    }
    // Fetch data from API and store it in cookies
    axios
      .get(`${Base}/api/user/view_upcoming`)
      .then((e) => {
        setImgSlider(e?.data.data);
        Cookies.set("upcomingImages", JSON.stringify(e?.data.data), {
          expires: 1,
        }); // Save data in cookies for 7 days
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    sliderImg();
  }, []);

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        className="testimonialSlider h-100"
      >
        {imgSlider.length > 0 &&
          imgSlider.map((img, index) => {
            return (
              <SwiperSlide key={index} className="">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-12 mx-auto">
                    <img
                      alt="slider-image"
                      className="img-fluid"
                      src={img.image}
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
};

export default UpcommingSlider;
